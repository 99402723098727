import { Dispatch, SetStateAction } from "react";
import { PartyMembership } from "./PartyMembership";
import { RoleCard } from "./RoleCard";
import TwoSidedCard from "./TwoSidedCard";
import { useSelector } from "react-redux";
import { selectUserDetails } from "../store/game/selectors";

type Props = {
  hideSet?: false | "role" | "party";
  onHideSet?: Dispatch<SetStateAction<false | "role" | "party">>;
};

const Roles = ({ hideSet, onHideSet }: Props) => {
  const myDetails = useSelector(selectUserDetails);
  return (
    <>
      {myDetails?.role?.party && myDetails?.role?.role && (
        <div className="Cards">
          <TwoSidedCard
            delay={10}
            onTurn={() => onHideSet?.("party")}
            onTurnBack={() => onHideSet?.(false)}
          >
            <PartyMembership type="back" />
            <PartyMembership type={myDetails?.role?.party} />
          </TwoSidedCard>
          {hideSet !== "party" && (
            <TwoSidedCard
              shouldConfirm
              delay={10}
              onTurn={() => onHideSet?.("role")}
              onTurnBack={() => onHideSet?.(false)}
            >
              <RoleCard type="back" />
              <RoleCard type={myDetails?.role?.role} />
            </TwoSidedCard>
          )}
        </div>
      )}
    </>
  );
};

export default Roles;
