import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUser, saveUser } from "../../utils/firebase";
import * as reducers from "./actions";

export type State = {
  state: "idle" | "joined";
  lang: "en" | "hu";
  user?: {
    isLoggedIn?: boolean;
    name: string;
  };
};

const initialState: State = {
  state: "idle",
  lang: "en",
};

export const login = createAsyncThunk("room/user", async (userId: string) => {
  if (!userId) {
    return;
  }

  const response = await getUser(userId);

  return response;
});

export const roomSlice = createSlice({
  name: "room",
  initialState,
  reducers,
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      if (
        action.payload?.exists() &&
        action.payload?.data().isLoggedIn &&
        action.payload?.data().lastActionTime > Date.now() - 3600000
      ) {
        alert("This username is already taken");
      } else {
        state.user = {
          ...action.payload?.data(),
          isLoggedIn: true,
          name: action.meta.arg,
        };

        saveUser(state.user.name, {
          ...state.user,
          loggedInTime: Date.now(),
        });
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const { logout, joinToRoom, abandonFromRoom, rehydrateUser } =
  roomSlice.actions;

export default roomSlice.reducer;
