import { useSelector, useDispatch } from "react-redux";
import { DetailsGrid, Input, Toggle } from "./StyledComponents";
import { useCallback, useMemo, useState, useEffect } from "react";
import {
  RoomState,
  add,
  addUserToRoom,
  rehydrateRooms,
  removeUserFromRoom,
  start,
  end,
  killUser,
  Users,
  addCandidate,
  startVoting,
  endVoting,
  rehydrateVotes,
  VoteRecord,
} from "../store/room/reducers";
import { styled } from "styled-components";
import { abandonFromRoom, joinToRoom } from "../store/game/reducers";
import { subscribe } from "../utils/firebase";
import { minUsers, maxUsers } from "../configs/game";
import { generateRoles, sortUsers } from "../utils/game";
import { where } from "firebase/firestore";
import Voting from "./Voting";
import Roles from "./Roles";
import { selectUserDetails } from "../store/game/selectors";
import { selectRooms } from "../store/room/selectors";

const Container = styled(DetailsGrid)`
  .new-room {
    align-self: flex-start;
  }

  .buttons {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    & > input {
      margin: 0;
    }

    .toggle {
      display: flex;
      align-items: center;
    }
  }

  .room {
    border-bottom: 1px solid black;
    padding-bottom: 5px;

    .name {
      text-align: left;
      margin-bottom: 5px;
    }

    .details {
      font-size: 12px;
      font-style: italic;
      font-weight: normal;
      font-family: Arial;
    }

    .users {
      display: flex;
      flex-wrap: wrap;
      font-family: Arial;
      font-weight: normal;
      gap: 5px;
      margin-bottom: 5px;

      .user {
        cursor: pointer;
        background: white;
        border: 1px solid black;
        // margin: 2px;
        border-radius: 5px;
        padding: 5px;

        &:hover {
          opacity: 0.8;
        }

        &.group {
          opacity: 1 !important;
          border: none;
          background: transparent;
          padding-left: 0 !important;
        }

        &.you {
          font-weight: bold;
        }

        &.bad {
          background: ${({ theme }) => theme.bad};
        }
        &.good {
          background: ${({ theme }) => theme.good};
        }
        &.badGuy {
          background: black;
          color: white;
        }
        &.dead {
          opacity: 0.8;
          text-decoration: line-through;
          cursor: not-allowed;
          pointer-events: none;
        }
        &.last-elected {
          opacity: 0.8;
          cursor: not-allowed;
          pointer-events: none;
        }

        .candidate {
          background: ${({ theme }) => theme.red};
          border: 1px solid black;
          border-radius: 5px;
          padding: 3px;
          margin-right: 3px;
        }
      }

      &.hide-roles {
        .user:not(.group) {
          color: black !important;
          background: white !important;
          border: 1px solid black !important;
        }
      }
    }

    &.started {
      .users {
        .user {
          padding: 10px;
        }
      }
    }
  }
`;

const Rooms = () => {
  const rooms = useSelector(selectRooms);
  const myDetails = useSelector(selectUserDetails);
  const roomNames = useMemo(
    () => Object.values(rooms).map((room) => room?.name),
    [rooms],
  );
  const [newRoom, setNewRoom] = useState<string>();
  const [hideUserRoles, setHiderUserRoles] = useState<false | "party" | "role">(
    false,
  );
  const dispatch = useDispatch();
  const candidates = myDetails?.room?.candidates;

  // szavazas inditas, szavazatok szamanak kijelzese,  - done
  //lezaras utan eredmeny mutatasa. - done
  // a statokat pedig taroljuk az adatbazisban. Jatek vegenel megmutathatjuk mindenkinek.
  // ha a szavazas elindult, a kartyak csak akkor jelennek meg - done
  // azt is, hogy ki hanyszor volt elnok, kancellar, stb. ehhez viszont kell csinalni egy stat adatbazist is, amiben eltaroljuk, mert egy szobaban tobb jatekot is lehet jatszni
  // megoles funkcio - done
  // ha badGuyt megolik akkor jatek vege - done
  // ha badGuyt kancellarnak valasztjak, a jateknak vege
  // ha valaki meghalt, kihuzzuk a nevet es onnantol nem valaszhato, ehhez kell majd state, hogy: dead - done

  // szavazasra jeloles: sima klikk, max 2 fo, elso az elnok, masodik a kancellar: candidates
  // megoles: dupleklikk - done

  const startGame = useCallback(
    (room: RoomState) => {
      const roledUsers = generateRoles(room.users);

      dispatch(start({ room, users: roledUsers }));
    },
    [dispatch],
  );

  const abandonRoom = useCallback(
    (room: RoomState) => {
      if (myDetails?.name) {
        dispatch(removeUserFromRoom({ room, user: myDetails?.name }));
      }
      dispatch(abandonFromRoom());
    },
    [dispatch, myDetails?.name],
  );

  useEffect(() => {
    if (!myDetails?.room) {
      dispatch(abandonFromRoom());
    }
  }, [dispatch, myDetails?.room]);

  const joinRoom = useCallback(
    (room: RoomState) => {
      dispatch(joinToRoom());
      if (myDetails?.name) {
        dispatch(addUserToRoom({ room, user: myDetails?.name }));
      }
    },
    [dispatch, myDetails?.name],
  );

  const kill = useCallback(
    (room: RoomState, userToBeDead: string) => {
      if (
        room.state === "started" &&
        confirm("Are you sure you want to kill?")
      ) {
        dispatch(killUser({ room, user: userToBeDead }));
      }
    },
    [dispatch],
  );

  const createRoom = useCallback(() => {
    if (!newRoom || !myDetails?.name || !myDetails?.isLoggedIn) {
      return;
    }
    const room: RoomState = {
      state: "idle",
      name: newRoom,
      created: Date.now(),
      creator: myDetails?.name,
      users: {},
      doesBadGuyKnowBads: true,
      badGuyCanBeKilled: false,
      winner: "",
      votes: [],
    };
    setNewRoom(undefined);

    dispatch(add(room));
    joinRoom(room);
  }, [newRoom, myDetails?.name, myDetails?.isLoggedIn, dispatch, joinRoom]);

  useEffect(() => {
    if (!myDetails?.room?.currentVote) {
      return;
    }

    const unsubscribe = subscribe(
      "votes",
      (newVotes) => {
        if (myDetails.room?.currentVote?.started) {
          dispatch(
            rehydrateVotes({
              room: myDetails.room,
              votes: newVotes as VoteRecord[],
            }),
          );
        }
      },
      [
        where("room", "==", myDetails.room.name),
        where("voteId", "==", myDetails.room.currentVote.started),
      ],
    );

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, myDetails?.room?.currentVote?.started, myDetails?.room?.name]);

  useEffect(() => {
    const unsubscribe = subscribe("rooms", (newRooms) => {
      dispatch(
        rehydrateRooms(
          newRooms.reduce<Record<string, RoomState>>((acc, curr) => {
            const room = curr as RoomState;
            acc[room.name] = room;

            return acc;
          }, {}),
        ),
      );
    });

    return unsubscribe;
  }, [dispatch]);

  return (
    <Container isFlex>
      {!myDetails?.room || myDetails.room.state !== "started" ? (
        <div className="new-room">
          <span>New room</span>
          <Input
            value={newRoom || ""}
            disabled={!myDetails?.isLoggedIn}
            onChange={(e) => setNewRoom(e.target.value)}
          />
          <Input
            type="button"
            disabled={
              !newRoom ||
              roomNames.includes(newRoom) ||
              !myDetails?.name ||
              !!myDetails?.room
            }
            value="Create"
            onClick={createRoom}
          />
        </div>
      ) : null}
      {Object.entries(rooms ?? {}).map(([, room]) => {
        if (
          !room ||
          (myDetails?.room?.state === "started" &&
            myDetails?.room?.name !== room.name)
        ) {
          return null;
        }

        const isJoined = myDetails?.room?.name === room.name;
        const isCreator = isJoined && myDetails?.name === room.creator;

        return (
          <>
            <div className={`room ${room.state}`}>
              <div className="name">
                {" "}
                {room.name}{" "}
                {room.state !== "idle" && (
                  <span className="details">({room.state})</span>
                )}
              </div>
              {["joined", "candidates"].map((group) => {
                if (
                  group === "candidates" &&
                  (!isCreator ||
                    !candidates?.length ||
                    room.state !== "started")
                ) {
                  return null;
                }

                const users =
                  group === "joined"
                    ? sortUsers(room.users ?? {})
                    : (candidates ?? []).reduce<Users>((acc, curr) => {
                        acc[curr] = room.users[curr];

                        return acc;
                      }, {});

                return (
                  <div className={`users ${hideUserRoles ? "hide-roles" : ""}`}>
                    <div className="user group">
                      {group === "joined" ? "Joined" : "Candidates"}
                    </div>
                    {Object.entries(users)
                      .filter(([username, details]) => {
                        if (group === "joined") {
                          return details?.joined;
                        }
                        return candidates?.includes(username);
                      })
                      .map(([username, details], index) => {
                        const isMe = username === myDetails?.name;
                        const suffixes = [];
                        const classes = ["user"];
                        if (
                          room.state === "ended" ||
                          (room.state === "started" &&
                            (isMe ||
                              myDetails?.role?.role === "bad" ||
                              (myDetails?.role?.role === "badGuy" &&
                                (myDetails.room?.usersLength ?? 0) <= 6)))
                        ) {
                          classes.push(details?.role ?? "");
                        }
                        if (room.creator === username) {
                          suffixes.push("admin");
                        }
                        if (isMe) {
                          suffixes.push("you");
                        }
                        if (details?.dead) {
                          classes.push("dead");
                        }

                        if (
                          myDetails?.room?.lastVote?.state === "elected" &&
                          [
                            myDetails?.room?.lastVote?.president,
                            myDetails?.room?.lastVote?.chancellor,
                          ].includes(username)
                        ) {
                          classes.push("last-elected");
                        }

                        return (
                          <div
                            className={`${suffixes.join(" ")} ${classes.join(
                              " ",
                            )}`}
                            onDoubleClick={() => {
                              if (isCreator && room.state === "started") {
                                kill(room, username);
                              }
                            }}
                            onClick={() => {
                              if (
                                isCreator &&
                                room.state === "started" &&
                                room.currentVote?.state !== "in-progress" &&
                                (!candidates?.includes(username) ||
                                  candidates?.length === 2)
                              ) {
                                dispatch(
                                  addCandidate({ room, candidate: username }),
                                );
                              }
                            }}
                          >
                            {group === "candidates" ? (
                              <span className="candidate">
                                {index ? "Chancellor:" : "President:"}
                              </span>
                            ) : (
                              ""
                            )}
                            {username}{" "}
                            {suffixes.length ? `(${suffixes.join(", ")})` : ""}
                          </div>
                        );
                      })}
                  </div>
                );
              })}
              {isCreator && room.state === "started" && (
                <div className="buttons"></div>
              )}

              <div className="buttons">
                {isCreator ? (
                  <>
                    {room.state !== "started" && (
                      <Input
                        type="button"
                        value="Start Game"
                        disabled={
                          Object.keys(room.users).length < minUsers ||
                          Object.keys(room.users).length > maxUsers
                        }
                        onClick={() => startGame(room)}
                      />
                    )}
                    {room.state === "started" && (
                      <>
                        <Input
                          type="button"
                          value={
                            room.currentVote?.state !== "in-progress"
                              ? "Start Voting"
                              : "End Voting"
                          }
                          disabled={
                            candidates?.length !== 2 ||
                            (myDetails?.room?.lastVote?.state === "elected" &&
                              ([candidates[0], candidates[1]].includes(
                                myDetails?.room?.lastVote?.president,
                              ) ||
                                [candidates[0], candidates[1]].includes(
                                  myDetails?.room?.lastVote?.chancellor,
                                )))
                          }
                          onClick={() => {
                            if (room.currentVote?.state !== "in-progress") {
                              dispatch(startVoting(room));
                            } else {
                              dispatch(endVoting(room));
                            }
                          }}
                        />
                        <span className="toggle">
                          <Toggle
                            id="badGuy-can-be-killed"
                            defaultChecked={myDetails?.room?.badGuyCanBeKilled}
                            onChange={() => {}}
                          />
                          <label htmlFor="badGuy-can-be-killed">
                            BadGuy can be killed?
                          </label>
                        </span>
                        {/* <Input
                        type="button"
                        value="Restart Game"
                        onClick={() => {
                          if (confirm("Are you sure you want to restart?")) {
                            dispatch(restart(room));
                          }
                        }}
                      /> */}
                        <Input
                          type="button"
                          value="End Game"
                          onClick={() => {
                            if (confirm("Are you sure you want to end?")) {
                              dispatch(end(room));
                            }
                          }}
                        />
                      </>
                    )}
                  </>
                ) : null}
                {isJoined && room.state !== "started" && (
                  <Input
                    type="button"
                    value="Abandon"
                    onClick={() => abandonRoom(room)}
                  />
                )}
                {!isJoined && room.state !== "started" && (
                  <Input
                    type="button"
                    value="Join"
                    disabled={!myDetails?.isLoggedIn || !!myDetails?.room}
                    onClick={() => joinRoom(room)}
                  />
                )}
              </div>
            </div>
            {room.name === myDetails?.room?.name && room.state !== "idle" && (
              <>
                <Voting />
                <Roles hideSet={hideUserRoles} onHideSet={setHiderUserRoles} />
              </>
            )}
          </>
        );
      })}
    </Container>
  );
};

export default Rooms;
