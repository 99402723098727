import { createSlice } from "@reduxjs/toolkit";
import * as reducers from "./actions";

export type Party = "bad" | "good";
export type Role = Party | "badGuy";
export type User = {
  joined: boolean | undefined;
  dead?: boolean;
  role?: Role;
  party?: Party;
};
export type Users = Record<string, User | undefined>;
export type Vote = {
  started: number;
  state: "waiting" | "in-progress" | "elected" | "failed";
  president: string;
  chancellor: string;
  votes: Record<string, VoteRecord["vote"]>;
};
export type RoomState = {
  state: "idle" | "started" | "ended";
  created: number;
  name: string;
  users: Users;
  creator: string;
  doesBadGuyKnowBads: boolean;
  badGuyCanBeKilled: boolean;
  votes?: Vote[];
  currentVote?: Vote;
  candidates?: string[];
  winner: `${Party}s` | "";
};

export type VoteRecord = {
  name: string;
  vote: "yes" | "no";
  voteId: number;
  room: string;
};

export type State = {
  rooms: Record<string, RoomState | undefined>;
};

const initialState: State = {
  rooms: {},
};

export const roomSlice = createSlice({
  name: "room",
  initialState,
  reducers,
});

// Action creators are generated for each case reducer function
export const {
  restart,
  start,
  end,
  add,
  remove,
  rehydrateRooms,
  rehydrateVotes,
  addUserToRoom,
  removeUserFromRoom,
  killUser,
  addCandidate,
  startVoting,
  endVoting,
  vote,
} = roomSlice.actions;

export default roomSlice.reducer;
