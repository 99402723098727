import { styled } from "styled-components";
import Card from "./Card";

import { ReactComponent as RoleGood } from "../icons/role-good.svg";
import { ReactComponent as RoleBad } from "../icons/role-bad.svg";
import { ReactComponent as RoleBadGuy } from "../icons/role-badGuy.svg";
import { ReactComponent as RoleBack } from "../icons/role-back.svg";

const Container = styled(Card)`
  padding: 0;

  & > .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    & > svg {
      width: 100%;
      height: 100%;
    }
  }
`;

type Props = { type: "back" | "good" | "bad" | "badGuy" };

export const RoleCard = ({ type }: Props) => {
  return (
    <Container className={type} type="vertical">
      <div className="wrapper">
        {type === "good" && <RoleGood />}
        {type === "bad" && <RoleBad />}
        {type === "badGuy" && <RoleBadGuy />}
        {type === "back" && <RoleBack />}
      </div>
    </Container>
  );
};

RoleCard.displayName = "RoleCard";
