import { selectUserDetails } from "../store/game/selectors";
import { vote } from "../store/room/reducers";
import { BallotCard } from "./BallotCard";
import { useSelector, useDispatch } from "react-redux";

const Voting = () => {
  const myDetails = useSelector(selectUserDetails);
  const dispatch = useDispatch();

  return (
    <>
      {myDetails?.room?.winner === "goods" && "BadGuy killed. The Goods won!"}
      {myDetails?.room?.currentVote && myDetails?.name && (
        <div className="votes">
          <div className="candidate">
            <div>
              President: <b>{myDetails.room.currentVote.president}</b>
            </div>
            <div>
              Chancellor: <b>{myDetails.room.currentVote.chancellor}</b>
            </div>
          </div>
          {myDetails.room.currentVote.state !== "waiting" && (
            <div className="vote-counts">
              {myDetails.room.currentVote.state === "in-progress" ? (
                <>
                  {Object.keys(myDetails.room.currentVote.votes).length} of{" "}
                  {Object.keys(myDetails.room.users).length} users voted
                </>
              ) : (
                <>
                  {
                    Object.values(myDetails.room.currentVote.votes).filter(
                      (userVote) => userVote === "yes",
                    ).length
                  }{" "}
                  of {Object.keys(myDetails.room.currentVote.votes).length}{" "}
                  users voted for YES
                  <br />
                  {myDetails.room.winner === "bads" &&
                    "BadGuy elected as the chancellor. The Bads won!"}
                  {!myDetails.room.winner && (
                    <>
                      President and chancellor{" "}
                      {myDetails.room.currentVote.state == "elected"
                        ? ""
                        : "not "}
                      elected
                    </>
                  )}
                </>
              )}
            </div>
          )}
          <div className="Cards">
            {!myDetails.room.currentVote.votes[myDetails.name] ||
            myDetails.room.currentVote.votes[myDetails.name] === "yes" ? (
              <BallotCard
                type="ja"
                disabled={myDetails.room.currentVote.state !== "in-progress"}
                isVoted={
                  myDetails.room.currentVote.votes[myDetails.name] === "yes"
                }
                onClick={() =>
                  dispatch(
                    vote({
                      room: myDetails.room,
                      vote: "yes",
                      user: myDetails.name,
                    }),
                  )
                }
              />
            ) : null}
            {!myDetails.room.currentVote.votes[myDetails.name] ||
            myDetails.room.currentVote.votes[myDetails.name] === "no" ? (
              <BallotCard
                type="nein"
                disabled={myDetails.room.currentVote.state !== "in-progress"}
                isVoted={
                  myDetails.room.currentVote.votes[myDetails.name] === "no"
                }
                onClick={() =>
                  dispatch(
                    vote({
                      room: myDetails.room,
                      vote: "no",
                      user: myDetails?.name,
                    }),
                  )
                }
              />
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default Voting;
