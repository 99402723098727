import type { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { RoomState, State, Users, VoteRecord } from "./reducers";
import { addVote, removeRoom, saveRoom } from "../../utils/firebase";
import { getElectionResult } from "../../utils/game";

type R<T> = CaseReducer<State, PayloadAction<T>>;

// type RN = CaseReducer<State>;

export const start: R<{ room: RoomState; users: Users }> = (state, action) => {
  const room = state.rooms[action.payload.room.name];

  if (room) {
    room.users = action.payload.users;
    room.state = "started";
    room.winner = "";
    room.candidates = [];
    room.doesBadGuyKnowBads = Object.keys(room.users ?? {}).length <= 6;
    room.badGuyCanBeKilled = false;
    delete room.currentVote;
    state.rooms[action.payload.room.name] = room;

    saveRoom(room.name, room);
  }
};

export const end: R<RoomState> = (state, action) => {
  const room = state.rooms[action.payload.name];

  if (room) {
    room.state = "ended";
    state.rooms[action.payload.name] = room;

    saveRoom(room.name, room);
  }
};

export const restart: R<RoomState> = (state, action) => {
  const room = state.rooms[action.payload.name];

  if (room) {
    room.state = "idle";
    state.rooms[action.payload.name] = room;

    saveRoom(room.name, room);
  }
};

export const rehydrateRooms: R<Record<string, RoomState | undefined>> = (
  state,
  action,
) => {
  state.rooms = action.payload;
};

export const rehydrateVotes: R<{ room: RoomState; votes: VoteRecord[] }> = (
  state,
  action,
) => {
  const room = state.rooms[action.payload.room.name];
  const votes = action.payload.votes;
  if (room && room?.currentVote && votes) {
    votes.forEach((vote: VoteRecord) => {
      if (
        room.name === vote.room &&
        vote.voteId === room.currentVote?.started
      ) {
        room.currentVote.votes[vote.name] = vote.vote;
      }
    });
    saveRoom(room.name, room);
  }
};

export const add: R<RoomState> = (state, action) => {
  state.rooms[action.payload.name] = action.payload;
  saveRoom(action.payload.name, action.payload);
};

export const remove: R<RoomState> = (state, action) => {
  delete state.rooms[action.payload.name];

  removeRoom(action.payload.name);
};

export const killUser: R<{ room: RoomState; user: string }> = (
  state,
  action,
) => {
  const room = state.rooms[action.payload.room.name];
  const user = room?.users[action.payload.user];
  if (room && user) {
    user.dead = true;
    if (user.role === "badGuy") {
      room.state = "ended";
      room.winner = "goods";
    }
    saveRoom(room.name, room);
  }
};

export const addCandidate: R<{ room: RoomState; candidate: string }> = (
  state,
  action,
) => {
  const room = state.rooms[action.payload.room.name];

  if (room) {
    room.candidates = room.candidates || [];
    const lastVote = room.votes && room.votes[room.votes.length - 1];

    if (
      lastVote &&
      [lastVote.president, lastVote.chancellor].includes(
        action.payload.candidate,
      ) &&
      lastVote.state === "elected"
    ) {
      return;
    }

    if (room.candidates.length >= 2) {
      room.candidates = [action.payload.candidate];
    } else {
      room.candidates = [...room.candidates, action.payload.candidate];
    }

    saveRoom(room.name, room);
  }
};

export const startVoting: R<RoomState> = (state, action) => {
  const room = state.rooms[action.payload.name];

  if (room && room.candidates?.length === 2) {
    room.currentVote = {
      started: Date.now(),
      state: "in-progress",
      president: room.candidates[0],
      chancellor: room.candidates[1],
      votes: {},
    };
    saveRoom(room.name, room);
  }
};

export const endVoting: R<RoomState> = (state, action) => {
  const room = state.rooms[action.payload.name];
  if (room && room.currentVote) {
    const result = getElectionResult(room, true);
    if (result) {
      room.currentVote.state = result;
      room.votes = [...(room.votes ?? []), { ...room.currentVote }];

      if (
        result === "elected" &&
        room.users[room.currentVote.chancellor]?.role === "badGuy"
      ) {
        room.state = "ended";
        room.winner = "bads";
      }
    }

    saveRoom(room.name, room);
  }
};

export const vote: R<{
  room: RoomState;
  user: string;
  vote: VoteRecord["vote"];
}> = (state, action) => {
  const room = state.rooms[action.payload.room.name];
  const user = action.payload.user;
  const voteAnswer = action.payload.vote;

  if (
    room &&
    room.users[user] &&
    room.currentVote?.state === "in-progress" &&
    room.currentVote.votes &&
    ["yes", "no"].includes(voteAnswer)
  ) {
    addVote(room, user, voteAnswer);
    const result = getElectionResult(room);

    if (result) {
      room.currentVote.state = result;
      room.votes = [...(room.votes ?? []), { ...room.currentVote }];

      if (
        result === "elected" &&
        room.users[room.currentVote.chancellor]?.role === "badGuy"
      ) {
        room.state = "ended";
        room.winner = "bads";
      }
    }

    saveRoom(room.name, room);
  }
};

export const removeUserFromRoom: R<{
  room: RoomState;
  user: string;
}> = (state, action) => {
  const room = state.rooms[action.payload.room.name];
  const user = action.payload.user;

  if (!user) {
    return;
  }

  if (room && user) {
    delete room.users[user];
  }

  const users = Object.entries(room?.users ?? {}).reduce<string[]>(
    (acc, [username, loggedin]) => {
      if (loggedin) {
        return [...acc, username];
      }

      return acc;
    },
    [],
  );

  if (room && users.length === 0) {
    delete state.rooms[room.name];
    removeRoom(room.name);
  } else if (room) {
    if (user === room.creator) {
      room.creator = users[0];
    }
    saveRoom(room.name, room);
  }
};

export const addUserToRoom: R<{ room: RoomState; user: string }> = (
  state,
  action,
) => {
  const room = state.rooms[action.payload.room.name];
  const user = action.payload.user;
  if (room && user) {
    room.users[user] = {
      joined: true,
    };
    saveRoom(room.name, room);
  }
};
