import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  // addDoc,
  getFirestore,
  collection,
  setDoc,
  doc,
  getDoc,
  getDocs,
  deleteDoc,
  query,
  onSnapshot,
  QueryConstraint,
} from "firebase/firestore";
import { config } from "../firebase.config";
import { RoomState } from "../store/room/reducers";

// Initialize Firebase
const app = initializeApp(config);
getAnalytics(app);
export const db = getFirestore(app);
export const saveUser = (
  id: string | undefined,
  docObj: Record<string, unknown>,
) => {
  if (id) {
    setDoc(doc(db, "users", id), { ...docObj, lastActionTime: Date.now() });
  }
};

export const getUser = (id: string | undefined) => {
  if (id) {
    return getDoc(doc(db, "users", id));
  }
};

export const addVote = (
  room: RoomState,
  username: string,
  vote: "yes" | "no",
) => {
  if (room.currentVote) {
    setDoc(
      doc(db, "votes", `${room.name}-${room.currentVote?.started}-${username}`),
      {
        name: username,
        room: room.name,
        vote,
        voteId: room.currentVote.started,
      },
    );
  }
};

export const saveRoom = (id: string | undefined, docObj: RoomState) => {
  if (!id) {
    return;
  }

  if (docObj.currentVote) {
    setDoc(doc(db, "rooms", id), {
      ...docObj,
      currentVote: {
        ...docObj.currentVote,
        votes: {},
      },
    });
  } else {
    setDoc(doc(db, "rooms", id), docObj);
  }
};
export const removeRoom = (id: string | undefined) => {
  if (id) {
    deleteDoc(doc(db, "rooms", id));
  }
};

export const getRoom = (id: string | undefined) => {
  if (id) {
    return getDoc(doc(db, "rooms", id));
  }
};

export const getRooms = async () => {
  const docCollection = await getDocs(collection(db, "rooms"));
  const docs: Record<string, RoomState> = {};
  docCollection.forEach((room) => {
    const roomData = room.data() as RoomState;

    docs[roomData.name] = roomData;
  });

  return docs;
};

export const subscribe = (
  collectionId: string,
  onChanged: (newData: unknown[]) => void,
  constraints?: QueryConstraint[],
) => {
  const q = query(collection(db, collectionId), ...(constraints ?? []));

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const data: unknown[] = [];
    snapshot.forEach((docData) => {
      data.push(docData.data());
    });
    onChanged(data);
  });
  return unsubscribe;
};
