import { createSelector } from "@reduxjs/toolkit";
import { selectState } from "../root-selector";
import find from "lodash/find";
import { RoomState, Vote } from "../room/reducers";
import { selectRooms } from "../room/selectors";

export const selectGameState = createSelector(
  selectState,
  (state) => state.game,
);

const selectUser = createSelector(selectGameState, (state) => state.user);
export const selectUsername = createSelector(
  selectUser,
  (state) => state?.name,
);

export const selectUserDetails = createSelector(
  [selectUser, selectRooms],
  (user, rooms) => {
    const userRoom = find(rooms, (room) => {
      return !!room?.users[user?.name ?? ""];
    })?.name;
    if (user) {
      const room = rooms[userRoom ?? ""];
      const role = room?.users[user.name];
      if (room) {
        return {
          ...user,
          room: {
            ...room,
            lastVote: room.votes && room.votes[room.votes.length - 1],
            usersLength: Object.keys(room?.users ?? {}).length,
          } as RoomState & { usersLength: number; lastVote?: Vote },
          role,
        };
      }

      return { ...user, room, role };
    }

    return;
  },
);
