import type { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { State } from "./reducers";
import { saveUser } from "../../utils/firebase";

type R<T> = CaseReducer<State, PayloadAction<T>>;

type RN = CaseReducer<State>;

export const setLang: R<State["lang"]> = (state, action) => {
  state.lang = action.payload;
};

export const logout: RN = (state) => {
  state.state = "idle";
  if (state.user) {
    state.user = {
      ...state.user,
      isLoggedIn: false,
    };

    saveUser(state.user.name, {
      ...state.user,
      loggedInTime: 0,
    });

    state.user.name = "";
    state.user.isLoggedIn = false;
  }
};

export const rehydrateUser: R<State["user"]> = (state, action) => {
  state.user = action.payload;
};

export const abandonFromRoom: RN = (state) => {
  if (state.user) {
    state.state = "idle";

    saveUser(state.user.name, state.user);
  }
};

export const joinToRoom: RN = (state) => {
  if (state.user) {
    state.state = "joined";

    saveUser(state.user.name, state.user);
  }
};
