type Game = {
  bads: number;
  doesBadGuyKnowBads?: boolean;
};

const config: Record<5 | 6 | 7 | 8 | 9 | 10, Game> = {
  5: {
    bads: 2,
    doesBadGuyKnowBads: true,
  },
  6: {
    bads: 2,
    doesBadGuyKnowBads: true,
  },
  7: {
    bads: 3,
  },
  8: {
    bads: 3,
  },
  9: {
    bads: 4,
  },
  10: {
    bads: 4,
  },
};

export const minUsers = 5;
export const maxUsers = 10;

export default config;
