import { styled } from "styled-components";
import Card from "./Card";

import { ReactComponent as BorderLine } from "../icons/border-line.svg";

const Container = styled(Card)<{ disabled?: boolean }>`
  font-size: 15vw;
  font-family: germaniaregular;

  &.ja {
    fill: black;
  }

  &.nein {
    background: black;
    fill: white;
    color: white;
  }

  &.voted,
  &.disabled {
    pointer-events: none;
  }
  &.disabled {
    opacity: 0.5;
  }

  & > .wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
`;

const Border = styled(BorderLine)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

type Props = {
  type: "ja" | "nein";
  disabled?: boolean;
  isVoted?: boolean;
  onClick?: () => void;
};

export const BallotCard = ({ type, isVoted, disabled, onClick }: Props) => {
  return (
    <Container
      className={`${type} ${isVoted ? "voted" : ""} ${
        disabled ? "disabled" : ""
      }`}
      type="horizontal"
      onClick={onClick}
    >
      <div className="wrapper">
        <Border />
        {type}
      </div>
    </Container>
  );
};

BallotCard.displayName = "BallotCard";
