import { PropsWithChildren } from "react";
import { styled } from "styled-components";

const Container = styled.div`
  border-radius: 2vw;
  background: white;
  color: black;
  width: 36vw;
  height: 25vw;
  margin: 2vw;
  padding: 2vw;
  cursor: pointer;
  overflow: hidden;

  &.vertical {
    width: 25vw;
    height: 36vw;
  }

  &.disabled,
  &[disabled] {
    cursor: not-allowed;
    pointer-events: none;
  }
`;

type Props = PropsWithChildren<{
  className?: string | null;
  type: "vertical" | "horizontal";
  onClick?: () => void;
}>;

const Card = ({ children, className, type, onClick }: Props) => {
  return (
    <Container className={`${className || ""} ${type}`} onClick={onClick}>
      {children}
    </Container>
  );
};

export default Card;

Card.displayName = "Card";
