import { styled } from "styled-components";
import Card from "./Card";

import { ReactComponent as Skull } from "../icons/skull.svg";
import { ReactComponent as Dove } from "../icons/dove.svg";
import { ReactComponent as Line } from "../icons/line.svg";
import { ReactComponent as Title } from "../icons/party-title.svg";
import { ReactComponent as Good } from "../icons/good.svg";
import { ReactComponent as Bad } from "../icons/bad.svg";

const Container = styled(Card)`
  background: ${({ theme }) => theme.yellow};
  display: flex;
  justify-content: center;
  align-items: center;

  & > .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-family: "Futura-Bold", Arial;
    font-size: 2vw;
    text-transform: uppercase;
    font-weight: bold;
    & > * {
      margin: 2vw 0;
    }
  }

  &.back {
    & > .wrapper {
      background: ${({ theme }) => theme.brown};
      fill: ${({ theme }) => theme.yellow};
      stroke: ${({ theme }) => theme.yellow};
      color: ${({ theme }) => theme.yellow};
    }
  }

  &:not(.back) {
    & > .wrapper {
      font-family: germaniaregular;
      font-size: 3vw;
      letter-spacing: 1vw;
    }
  }

  &.good {
    & > .wrapper {
      color: ${({ theme }) => theme.good};
    }
  }

  &.bad {
    & > .wrapper {
      font-family: germaniaregular;
      color: ${({ theme }) => theme.bad};
    }
  }
`;

type Props = { type: "back" | "good" | "bad" };

export const PartyMembership = ({ type }: Props) => {
  return (
    <Container className={type} type="vertical">
      <div className="wrapper">
        {type === "back" && (
          <>
            <Skull />
            Party
            <Line />
            Membership
            <Dove />
          </>
        )}
        {type !== "back" && (
          <>
            <Title />
            {type === "good" ? <Good /> : <Bad />}
            {type}
          </>
        )}
      </div>
    </Container>
  );
};

PartyMembership.displayName = "PartyMembership";
