/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useCallback } from "react";
import { ThemeProvider, styled } from "styled-components";
import { theme as mainTheme } from "../theme";
import { GlobalStyle } from "../global-style";
import { useSelector, useDispatch } from "react-redux";
import version from "../configs/release.json";

import {
  State as GameState,
  login,
  logout,
  rehydrateUser,
} from "../store/game/reducers";
import { rehydrateRooms, removeUserFromRoom } from "../store/room/reducers";
import { Input } from "./StyledComponents";
import Rooms from "./Rooms";
import store from "../store/store";
import { getRooms, getUser } from "../utils/firebase";
import { selectUserDetails } from "../store/game/selectors";

const Container = styled.div`
  text-align: center;
  height: 100%;
  min-width: 360px;
  padding: 10px 20px 20px;
  @media (max-width: 500px) {
    padding: 10px;
  }

  .version {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    height: 20px;
    font-family: Arial;
    font-size: 12px;
    padding: 2px;
  }

  .App-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-family: germaniaregular;
      font-size: calc(50px + 2vmin);
      color: white;

      @media (max-width: 570px) {
        font-size: calc(20px + 2vmin);
      }
    }

    .user {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .username {
        font-size: 20px;
      }
    }
  }

  .votes {
    .candidate {
      display: flex;
      justify-content: space-around;
      padding: 10px;
      font-size: 20px;
    }
  }

  .Cards {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
  }
`;

const App = () => {
  const myDetails = useSelector(selectUserDetails);
  const [username, setUsername] = useState("");
  const dispatch = useDispatch();

  const doLogout = useCallback(() => {
    const room = myDetails?.room;

    if (room) {
      dispatch(removeUserFromRoom({ room, user: myDetails.name }));
    }

    dispatch(logout());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myDetails?.room?.name, myDetails?.name, dispatch]);

  useEffect(() => {
    if (!myDetails?.name) {
      return;
    }
    getUser(myDetails.name)?.then((newUser) => {
      dispatch(rehydrateUser(newUser.data() as GameState["user"]));
    });
  }, [dispatch, myDetails?.name]);

  useEffect(() => {
    getRooms().then((newRooms) => {
      dispatch(rehydrateRooms(newRooms));
    });
  }, [dispatch]);

  return (
    <ThemeProvider theme={mainTheme}>
      <GlobalStyle />
      <Container>
        <header className="App-header">
          <span className="title">Secret BadGuy</span>
          <div className="user">
            {myDetails?.isLoggedIn ? (
              <span className="username">{myDetails.name}</span>
            ) : (
              <Input
                placeholder="Username"
                value={username || myDetails?.name || ""}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
            )}
            <Input
              type="button"
              onClick={() =>
                !myDetails?.isLoggedIn
                  ? store.dispatch(login(username))
                  : doLogout()
              }
              disabled={!myDetails?.isLoggedIn && !username}
              value={!myDetails?.isLoggedIn ? "Login" : "Logout"}
            />
          </div>
        </header>
        <Rooms />
        <div className="version">
          {version.version} at {version.date}
        </div>
      </Container>
    </ThemeProvider>
  );
};

export default App;
