import config from "../configs/game";
import sample from "lodash/sample";
import remove from "lodash/remove";
import { Role, RoomState, Users } from "../store/room/reducers";

type RoleGenerator = {
  badGuy: string[];
  bad: string[];
  good: string[];
};

export const getElectionResult = (room: RoomState, force?: boolean) => {
  if (!room.currentVote) {
    return;
  }

  const nonDeadUsers = Object.entries(room.users ?? {}).reduce<string[]>(
    (acc, [username, details]) => {
      if (details?.dead) {
        return acc;
      }

      return [...acc, username];
    },
    [],
  );
  const votedValues = Object.values(room.currentVote.votes);
  const yesCount = votedValues.filter(
    (voteValue) => voteValue === "yes",
  ).length;
  const noCount = votedValues.filter((voteValue) => voteValue === "no").length;

  if (force || nonDeadUsers.length === votedValues.length) {
    return yesCount > noCount ? "elected" : "failed";
  }

  return;
};

export const generateRoles = (users: Users): Users => {
  const roles: RoleGenerator = {
    badGuy: [],
    bad: [],
    good: [],
  };
  const unroled = Object.keys(users).filter((user) => !!users[user]);
  const length = unroled.length as keyof typeof config;
  const gameConfig = config[length];

  let currentRole: Role = "badGuy";
  let helper = 0;
  while (unroled.length && helper++ < 100) {
    const user = sample(unroled);
    if (user) {
      remove(unroled, (item) => item === user);
      roles[currentRole].push(user);
      if (currentRole === "badGuy") {
        currentRole = "bad";
      }
      if (currentRole === "bad" && roles.bad.length === gameConfig.bads - 1) {
        currentRole = "good";
        roles.good = [...unroled];
        break;
      }
    } else {
      break;
    }
  }

  if (!gameConfig) {
    throw new Error("Missing game config. This might be a tehcnical error.");
  }

  const newUsers: Users = {};
  Object.keys(roles).forEach((role) => {
    roles[role as keyof RoleGenerator].forEach((user) => {
      newUsers[user] = {
        joined: true,
        role: role as Role,
        party: role === "good" ? "good" : "bad",
      };
    });
  });

  return newUsers;
};

export const sortUsers = (users: Users): Users => {
  const keys = Object.keys(users);
  const sortedUsers: Users = {};

  keys.sort((a, b) => {
    if (a.toLowerCase() === b.toLowerCase()) {
      return 0;
    }

    if (a.toLowerCase() > b.toLowerCase()) {
      return 1;
    }

    return -1;
  });

  keys.forEach((user) => {
    sortedUsers[user] = users[user];
  });

  return sortedUsers;
};
