import {
  Children,
  PropsWithChildren,
  isValidElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { styled } from "styled-components";
import debounce from "lodash/debounce";

const Container = styled.div<{ className?: string | null }>`
  margin: 2vw;
  position: relative;
  transition: transform 0.8s;
  transform-style: preserve-3d;

  &.flipped {
    transform: rotateY(180deg);
  }

  & > * {
    position: absolute;
    margin: 0;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;

    &:nth-child(2) {
      transform: rotateY(180deg);
    }
  }
`;

type Props = PropsWithChildren<{
  className?: string | null;
  shouldConfirm?: boolean;
  delay?: number;
  onTurn?: () => void;
  onTurnBack?: () => void;
}>;

const TwoSidedCard = ({
  children,
  className,
  shouldConfirm,
  delay = 5,
  onTurn,
  onTurnBack,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const timeout = useRef<NodeJS.Timeout>();
  const [isFlipped, setFlipped] = useState(false);
  const [size, setSize] = useState<{ width: number; height: number }>();

  const applySize = useCallback(() => {
    const { width, height } =
      ref.current?.children[0]?.getBoundingClientRect() ?? {};

    if (width && height) {
      setSize({ width, height });
    }
  }, []);

  useEffect(() => {
    const onResize = debounce(() => {
      applySize();
    }, 10);

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [applySize]);

  useEffect(() => {
    applySize();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [applySize, children]);

  useEffect(() => {
    if (Children.count(children) !== 2) {
      throw new Error("Must have exactly two children");
    }

    const childrenArray = Children.toArray(children);
    if (
      childrenArray.some((child) => {
        if (!isValidElement(child)) {
          return false;
        }

        return !(child as typeof child & { type: { displayName: string } }).type
          .displayName;
      })
    ) {
      throw new Error("All children must have displayName");
    }

    if (
      childrenArray.some((child) => {
        if (typeof child !== typeof childrenArray[0]) {
          return true;
        }

        if (!isValidElement(child)) {
          return false;
        }

        return (
          (
            childrenArray[0] as (typeof childrenArray)[0] & {
              type: { displayName: string };
            }
          ).type.displayName !==
          (child as typeof child & { type: { displayName: string } }).type
            .displayName
        );
      })
    ) {
      throw new Error("Children must have same component type");
    }
  }, [children]);

  const onClick = useCallback(() => {
    if (isFlipped) {
      clearTimeout(timeout.current);
      setFlipped(false);
      onTurnBack?.();
    } else if (
      !shouldConfirm ||
      confirm("Are you sure you want to show you role card?")
    ) {
      clearTimeout(timeout.current);
      setFlipped(true);
      onTurn?.();
      timeout.current = setTimeout(() => {
        setFlipped(false);
        onTurnBack?.();
      }, delay * 1000);
    }
  }, [isFlipped, onTurn, onTurnBack]);

  return (
    <Container
      ref={ref}
      className={`${className || ""} ${isFlipped ? "flipped" : ""}`}
      style={size}
      onClick={onClick}
    >
      {children}
    </Container>
  );
};

export default TwoSidedCard;

TwoSidedCard.displayName = "TwoSidedCard";
