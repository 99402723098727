import { createSelector } from "@reduxjs/toolkit";
import { selectState } from "../root-selector";

export const selectRoomState = createSelector(
  selectState,
  (state) => state.room,
);

export const selectRooms = createSelector(
  selectRoomState,
  (state) => state.rooms,
);

export const selectRoom = (id: string) =>
  createSelector(selectRooms, (state) => state[id]);

export const selectCurrentVote = (roomId: string) =>
  createSelector(selectRoom(roomId), (state) => state?.currentVote);

export const selectLatesVote = (roomId: string) =>
  createSelector(
    selectRoom(roomId),
    (state) => state?.votes && state.votes[state.votes.length - 1],
  );
