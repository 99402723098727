import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'germaniaregular';
    src: url('${window.location.protocol}//${
      window.location.host
    }/fonts/germania.woff2') format('woff2'),
      url('${window.location.protocol}//${
        window.location.host
      }/fonts/germania.woff') format('woff');
    font-weight: normal;
    font-style: normal;

  }

  body {
    background-color: ${({ theme }) => theme.red} !important;
    margin: 0;
    font-family: "Futura-Bold", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    min-height: 100vh;
  }

  * {
    box-sizing: border-box;
  }
`;
