import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import roomReducer, { State as RoomState } from "./room/reducers";
import gameReducer, { State as GameState } from "./game/reducers";

export type State = {
  game: GameState;
  room: RoomState;
};

const rootReducer = combineReducers({
  game: gameReducer,
  room: roomReducer,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

export default configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
